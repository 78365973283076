import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation, Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import one from "../../assets/images/Home/Slider/1.svg";
import two from "../../assets/images/Home/Slider/1.svg";
import three from "../../assets/images/Home/Slider/3.svg";
import four from "../../assets/images/Home/Slider/4.svg";
import five from "../../assets/images/Home/Slider/5.svg";

const HomeSlider = () => {
  const data = [
    { id: 1, image: one },
    { id: 2, image: two },
    { id: 3, image: three },
    { id: 4, image: four },
    { id: 5, image: five },
    { id: 6, image: one },
    { id: 7, image: two },
    { id: 8, image: three },
    { id: 9, image: four },
    { id: 10, image: five },
  ];
  return (
    <div className="home-slider-componenet">
      <div className="top-text mb-3">
        <h1>
          User Flow <span>Information</span>
        </h1>
      </div>
      <div className="slider-component">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Navigation]}
          className="swiper_container"
        >
          {data?.map((dt) => (
            <>
              <SwiperSlide key={dt?.id}>
                <img loading="lazy" src={dt?.image} alt="slide_image" srcset={`${dt?.image} 320w, ${dt?.image} 480w, ${dt?.image} 800w`} sizes="(max-width: 600px) 100vw, 50vw" />
              </SwiperSlide>
            </>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default HomeSlider;
