import React from "react";
import { Col, Row } from "react-bootstrap";
import Image2 from "../../assets/images/Gallery/173160104546320662-min.jpg";
import Image3 from "../../assets/images/Gallery/173157038908089366-min.jpg";
// import Image4 from "../../assets/images/Gallery/17315692500414186-min.jpg";
// import Image8 from "../../assets/images/Gallery/173148405797590357-min.jpg";
import Image12 from "../../assets/images/Gallery/173143450905825391-min.jpg";
import Image13 from "../../assets/images/Gallery/173143450906052804-min.jpg";
import Image15 from "../../assets/images/Gallery/173143450906462860-min.jpg";
import Image16 from "../../assets/images/Gallery/173142538104472238-min.jpg";
import Image17 from "../../assets/images/Gallery/173142530296729018-min.jpg";
import Image9 from "../../assets/images/Gallery/173146722606081071-min.jpg";
// import Image10 from "../../assets/images/Gallery/17314357376744138-min.jpg";
// import Image11 from "../../assets/images/Gallery/173143573767574584-min.jpg";
import Image14 from "../../assets/images/Gallery/173143450906254349-min.jpg";
import Image5 from "../../assets/images/Gallery/173149730441399137-min.jpg";
import Image6 from "../../assets/images/Gallery/173149730441676916-min.jpg";
// import Image7 from "../../assets/images/Gallery/173149730441890145-min.jpg";


const Gallery = () => {
  const shared_images = [
    Image2,
    Image3,
    // Image4,
    Image5,
    Image6,
    Image17,
    // Image7,
    // Image8,
    Image9,
    // Image10,
    // Image11,
    Image12,
    Image13,
    Image14,
    Image15,
    Image16,

  ];
  return (
    <Row className="p-4 post_container mt-3">
      <div className="headers mb-3">
        <h4 className="title">Cloud Pictures, Images and Stock Photos</h4>
        <p className="desc">Cloud Pictures, Images and Stock Photos</p>
      </div>

      <Col xs={12} sm={6} md={6} lg={4} className="post-images">
        <div
          className={`post-images image-${
            shared_images.length > 4 ? "more" : shared_images?.length
            // : post.image.length
          }`}
        >
          {shared_images?.length > 0 ? (
            shared_images?.map((fav, index) => (
              <img src={fav} alt={`Image ${index + 1}`} key={index} srcset={`${fav} 320w, ${fav} 480w, ${fav} 800w`} sizes="(max-width: 600px) 100vw, 50vw" loading="lazy" />
            ))
          ) : (
            <p
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              Gallery Not Found
            </p>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default Gallery;
